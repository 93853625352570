import axios from "@/lib/axios";
import notify from "@/utilities/notifications";

const recordsApi = {
  getRecords() {
    return axios
      .get("/admin/records")
      .then((response) => {
        if (response.data.status == "success") {
          const recordsCount = [];
          for (const [key, value] of Object.entries(
            response.data.recordsCount
          )) {
            let color = "main-blue-text",
              icon = "mdi-account";
            switch (key) {
              case "teachers":
                color = color;
                icon = icon;
                break;
              case "students":
                color = "main-red-text";
                icon = "mdi-account-box";
                break;
              case "subjects":
                color = "main-teal-text";
                icon = "mdi-book-outline";
                break;
              case "sections":
                color = "main-green-text";
                icon = "mdi-account-multiple";
                break;
              default:
                break;
            }

            recordsCount.push({
              record: key,
              count: value,
              color: color,
              icon: icon,
            });
          }

          return recordsCount;
        }

        return false;
      })
      .catch((error) => {
        notify({
          status: "error",
          title: "Error",
          message: error.detail,
        });

        return false;
      });
  },
};

export default recordsApi;
