<template>
  <div>
    <v-card class="px-2">
      <v-card-title>
        <h2 class="mb-4">Statistics</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
            xs="12"
            v-for="(value, index) in recordsCount"
            :key="index"
          >
            <v-card class="mx-auto" outlined rounded="xl">
              <div :class="`module-border ${value.color}`">
                <v-list-item three-line>
                  <v-icon x-large :class="value.color">{{ value.icon }}</v-icon>
                  <v-list-item-content class="text-center">
                    <v-list-item-title class="headline mb-1">
                      <h3>{{ value.count }}</h3>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      value.record
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import recordsApi from "@/api/records";
export default {
  metaInfo: {
    title: "Dashboard",
  },
  name: "AdminDashboard",
  data() {
    return {
      recordsCount: {},
    };
  },
  async mounted() {
    const recordsCount = await recordsApi.getRecords();
    if (!recordsCount) {
      return (this.recordsCount = {});
    }

    this.recordsCount = recordsCount;
  },
};
</script>

<style>
.module-border {
  border-left-style: solid;
  border-left-width: 6px;
}
</style>
